import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import logo from '../static/temp.png';
import React, { useState } from 'react';

function NavigateWide() {
    return (
        <Navbar className="darkhead" bg="dark" variant="dark" expand="lg">
            <div className="container-fluid">
                <Navbar.Brand href="/">
                    <img src={logo} alt="Rank Everywhere Logo" className="logo" />
                    <h2>Rank Everywhere - Increase Your Business With More Buyers And More Transactions For More Profit</h2>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar" />
                <Navbar.Collapse id="navbar">
                    <Nav className="ms-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/stuff">Stuff</Nav.Link>
                        <Nav.Link href="/stuff">Things</Nav.Link>
                        <NavDropdown title="Dropdown" id="navdrop">
                            <NavDropdown.Item href="/link1">Link 1</NavDropdown.Item>
                            <NavDropdown.Item href="/link2">Link 2</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/link3">Link 3</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
}

export default NavigateWide;