import banner from '../static/ban.jpg';
import banimg from '../static/rank.png';
import React, { useState } from 'react';

function TextBanner() {
    return(
        <>
        <div className="banner w-100">
            <img src={banner} alt="Rank Everywhere Banner" class="w-100" height="800" />
            <div className="bantext w-30 text-center">
                <h2>RANK EVERYWHERE</h2>
                <p>is about having your very own BRANDED ONLINE PRESENCE.</p>
                <p>Regardless of how small or large your business is it needs its own VIRTUAL PORTAL for your business to receive VIRTUAL RECOGNITION.</p>
                <p>There are thousands of people online right now looking for your services...</p>
                <p>How many of them are finding you? Take the necessary steps to separate your business from its competition!</p>
                <img src={banimg} alt="Banner Image" className="w-50" />
            </div>
        </div>
        </>
    )
}

export default TextBanner;