import banvideo from '../static/banvid.mp4';
import React, { useState } from 'react';

function VidBanner() {
    return(
        <>
        <div className="vidbanner w-100 text-center">
            <video width="70%" height="auto" loop autoPlay muted playsInline>
                <source src={banvideo} type="video/mp4"></source>
            </video>
        </div>
        </>
    )
}

export default VidBanner;