import banner from '../static/ban.jpg';
import React, { useState } from 'react';

function StaticBanner() {
    return(
        <>
        <div className="banner w-100">
            <img src={banner} alt="Rank Everywhere Banner" class="w-100" height="600" />
        </div>
        </>
    )
}

export default StaticBanner;