import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import React, { useState } from 'react';

function TallForm() {
    return(
        <Col xs="3">
            <Container>
                <Form>
                    <Row>
                        <Form.Group controlId="tallFormEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" placeholder="yourname@gmail.com" />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group controlId="tallFormPhone">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="text" placeholder="123-456-7890" />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group controlId="tallFormPick">
                            <Form.Label>Do You Like Brisket?</Form.Label>
                            <Form.Select aria-label="Select an Option">
                                <option>Select One</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                                <option value="maybe">Maybe</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col xs="12" className="text-center">
                            <Button type="submit" variant="danger">
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Col>
    )
}

export default TallForm;