import {Container, Row, Col} from 'react-bootstrap';
import React, { useState } from 'react';

function LgBlurb(props) {
    return(
        <Container>
            <Row>
                <Col md="auto" className={props.bg}>
                    <h2>The story so far: In the beginning the Universe was created. This has made a lot of people very angry and been widely regarded as a bad move.</h2>
                    <h5>- Douglas Adams</h5>
                </Col>
            </Row>
        </Container>
    )
}

export default LgBlurb;