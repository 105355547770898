import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import React, { useState } from 'react';

function MinForm(props) {
    return(
        <Container>
            <Row>
                <Col xs="9" className={props.bg}>
                    <h1>Rank Everywhere</h1>
                    <h4>Subtitle Goes Here</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Maecenas volutpat blandit aliquam etiam. Eu consequat ac felis donec et odio pellentesque. Orci nulla pellentesque dignissim enim sit amet venenatis.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. In hac habitasse platea dictumst vestibulum rhoncus. Egestas egestas fringilla phasellus faucibus. Vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique. Bibendum ut tristique et egestas.</p>
                </Col>
                <Col xs="3">
                    <Container>
                        <Form>
                            <Row>
                                <Form.Group controlId="MinFormEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="email" placeholder="yourname@gmail.com" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group controlId="MinFormPhone">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="text" placeholder="123-456-7890" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col xs="12" className="text-center">
                                    <Button type="submit" variant="secondary">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default MinForm;