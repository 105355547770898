import React, { useState } from 'react';
import './App.css';
import Navigate from './comp/navbartight';
import NavigateWide from './comp/navbarwide';
import StaticBanner from './comp/staticbanner';
import TextBanner from './comp/textbanner';
import VidBanner from './comp/vidbanner';
import JustText from './comp/justtext';
import SmImgText from './comp/smimgtext';
import BigImgText from './comp/bigimgtext';
import VidText from './comp/vidtext';
import LgBlurb from './comp/lgblurb';
import SmBlurb from './comp/smblurbimg';
import InfoCards from './comp/infocards';
import TableSection from './comp/tables';
import WideForm from './comp/wideform';
import TallForm from './comp/tallform';
import MinForm from './comp/minform';
import CodeForm from './comp/firstcode';
import Gallery from './comp/gallery';
import CodeForm2 from './comp/secondcode.js';

function App() {
  const [code, setCode] = useState('1234');
  const [code2, setCode2] = useState('5678');
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  var codeReturn = (input) => {
    if(input === code){
      setFirst(true);
    }
  };

  var secondReturn = (input) => {
    if(input === code2){
      setSecond(true);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <NavigateWide />
      </header>
      <TextBanner />
      <div className="container-fluid tallform">
        <div className="col-12 offset-4">
          <CodeForm codeReturn={codeReturn} />
        </div>
      </div>
      {first && !second &&
        <>
        <Gallery />
        <div className="container-fluid tallform">
          <div className="col-12 offset-4">
            <CodeForm2 secondReturn={secondReturn} />
          </div>
        </div>
        </>
      }
      {first && second && !third &&
      <>
        <div className="container-fluid textwrapgreen">
          <BigImgText bg="bggreen" />
        </div>
        <div className="container-fluid textwrapwhite">
          <JustText bg="bgwhite" />
        </div>
        <div className="container-fluid textwrapblue">
          <TableSection />
        </div>
      </>
      }
      {third &&
        <>
        <div className="container-fluid textwrapgreen">
          <LgBlurb bg="bggreen text-center" />
        </div>
        <div className="container-fluid textwrapslate">
          <InfoCards bg="bgslate" />
        </div>
        <div className="container-fluid textwrapblue">
          <SmImgText bg="bgblue" />
        </div>
        <div className="container-fluid textwrapwhite">
          <SmBlurb bg="bgwhite text-center" />
        </div>
        <div className="container-fluid textwrapslate">
          <VidText bg="bgslate" />
        </div>
        <div className="container wideform">
          <WideForm />
        </div>
        <div className="container-fluid tallform">
          <div className="row">
            <TallForm />
            <TallForm />
            <TallForm />
          </div>
        </div>
        <div className="container-fluid textminform textwrapblue">
          <MinForm bg="bgblue" />
        </div>
        </>
      }
    </div>
  );
}

export default App;
