import {Button, Card, Container, Row, Col} from 'react-bootstrap';
import cardimg from '../static/temp.png';
import React, { useState } from 'react';

function InfoCards(props) {
    return(
        <Container>
            <Row>
                <Col xs="3" className={props.bg}>
                    <Card style={{ width: '75%' }}>
                        <Card.Img variant="top" src={cardimg} />
                        <Card.Body>
                            <Card.Title>Info Card</Card.Title>
                            <Card.Text>
                                Quick text about the info this card gives to the reader.
                            </Card.Text>
                            <Button variant="primary">Go Somewhere</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="3" className={props.bg}>
                    <Card style={{ width: '75%' }} bg="secondary">
                        <Card.Img variant="top" src={cardimg} />
                        <Card.Body>
                            <Card.Title>Info Card</Card.Title>
                            <Card.Text>
                                Quick text about the info this card gives to the reader.
                            </Card.Text>
                            <Button variant="primary">Go Somewhere</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="3" className={props.bg}>
                    <Card style={{ width: '75%' }} bg="dark">
                        <Card.Img variant="top" src={cardimg} />
                        <Card.Body>
                            <Card.Title>Info Card</Card.Title>
                            <Card.Text>
                                Quick text about the info this card gives to the reader.
                            </Card.Text>
                            <Button variant="primary">Go Somewhere</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="3" className={props.bg} bg="light">
                    <Card style={{ width: '75%' }}>
                        <Card.Img variant="top" src={cardimg} />
                        <Card.Body>
                            <Card.Title>Info Card</Card.Title>
                            <Card.Text>
                                Quick text about the info this card gives to the reader.
                            </Card.Text>
                            <Button variant="primary">Go Somewhere</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default InfoCards;