import {Container, Row, Col, Image} from 'react-bootstrap';
import React, { useState } from 'react';
import textimg from '../static/temp.png';

function BigImgText(props) {
    return(
        <Container>
            <Row className="d-flex align-items-center">
                <Col xs="6"><Image src={textimg} alt="Text Image" className="w-100 bigtextimg" rounded fluid /></Col>
                <Col xs="6" className={props.bg}>
                    <h1>Rank Everywhere</h1>
                    <h4>Subtitle Goes Here</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Maecenas volutpat blandit aliquam etiam. Eu consequat ac felis donec et odio pellentesque. Orci nulla pellentesque dignissim enim sit amet venenatis.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. In hac habitasse platea dictumst vestibulum rhoncus. Egestas egestas fringilla phasellus faucibus. Vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique. Bibendum ut tristique et egestas.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default BigImgText;