import {Container, Row, Col} from 'react-bootstrap';
import React, { useState } from 'react';

function JustText(props) {
    return(
        <Container>
            <Row>
                <Col md="auto" className={props.bg}>
                    <h1>Rank Everywhere</h1>
                    <h4>Subtitle Goes Here</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Maecenas volutpat blandit aliquam etiam. Eu consequat ac felis donec et odio pellentesque. Orci nulla pellentesque dignissim enim sit amet venenatis.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. In hac habitasse platea dictumst vestibulum rhoncus. Egestas egestas fringilla phasellus faucibus. Vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique. Bibendum ut tristique et egestas.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default JustText;