import {Tab, Tabs} from 'react-bootstrap';
import info from '../static/infosite.png';
import news from '../static/newssite.png';
import biz from '../static/bizsite.png';
import React, { useState } from 'react';

function Gallery(){
    return(
        <>
            <Tabs defaultActiveKey="info" id="examples" className="mb-3">
                <Tab eventKey="info" title="Information Site">
                    <img src={info} alt="Informational Site Example" />
                </Tab>
                <Tab eventKey="biz" title="Business Site">
                    <img src={biz}  alt="Business Site Example" />
                </Tab>
                <Tab eventKey="news" title="News Site">
                    <img src={news} alt="News Site Example" />
                </Tab>
            </Tabs>
        </>
    )
}

export default Gallery;