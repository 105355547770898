import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import React, { useState } from 'react';

function WideForm() {
    return(
        <Container>
            <Form>
                <Row>
                    <Col xs="6">
                        <Form.Group controlId="testWideUser">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" placeholder="jimbo"></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group controlId="testWidePass">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="******"></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Form.Group controlId="testWideBig">
                            <Form.Label>Type Your Comments Here:</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="text-center">
                        <Button variant="success" type="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default WideForm;