import {Container, Row, Col} from 'react-bootstrap';
import blurb from '../static/temp.png';
import React, { useState } from 'react';

function SmBlurb(props) {
    return(
        <Container>
            <Row>
                <Col md="auto" className={props.bg}>
                    <img src={blurb} alt="Blurb Img" class="w-25" />
                    <h3>The story so far: In the beginning the Universe was created. This has made a lot of people very angry and been widely regarded as a bad move.</h3>
                    <h5>- Douglas Adams</h5>
                </Col>
            </Row>
        </Container>
    )
}

export default SmBlurb;