import React, { useState } from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';

function CodeForm(props) {
    const [codeIn, setCodeIn] = useState('');

    var handleChange = (e) => {setCodeIn(e.target.value)};
    return(
        <Col xs="3">
            <Container>
                <Form>
                    <Row>
                        <Form.Group controlId="tallFormEmail">
                            <Form.Label>Input Your Demo Code to Continue</Form.Label>
                            <Form.Control type="text" onChange={handleChange} name="firstdemo" />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col xs="12" className="text-center">
                            <Button type="button" variant="danger" onClick={props.codeReturn(codeIn)}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Col>
    )
}

export default CodeForm;