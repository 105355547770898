import {Container, Row, Table} from 'react-bootstrap';
import React, { useState } from 'react';

function TableSection(props) {
    return(
        <Container>
            <Row className="text-center">
                <h3>RoI Chart</h3>
            </Row>
            <Row>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Item 1</th>
                            <th>Thing 2</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Rank Everywhere</td>
                            <td>SEO</td>
                            <td>Success</td>
                            <td>Started: Mar 10</td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
        </Container>
    )
}

export default TableSection;